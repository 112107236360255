<template>
  <form @submit.prevent="submit">
    <modal-card title="FixedVault™ credentials" :processing="processing">
      <section>
        <b-field
          :type="!formLabelIsValid ? 'is-danger' : ''"
          :message="!formLabelIsValid ? 'Please fill out this field.' : ''"
          label="Label *"
        >
          <b-input
            ref="label"
            v-model="form.label"
            :disabled="processing"
            required
            type="text"
            placeholder="eg. cPanel"
            @input="labelChanged = true"
          />
        </b-field>

        <b-field
          :type="!formValueIsValid ? 'is-danger' : ''"
          :message="!formValueIsValid ? 'Please fill out the field.' : ''"
          label="Username *"
        >
          <b-input
            v-model="form.username"
            :disabled="processing"
            type="text"
            password-reveal
            required
            placeholder="eg. example_user"
            @input="valueChanged = true"
          />
        </b-field>

        <b-field
          :type="!formValueIsValid ? 'is-danger' : ''"
          :message="!formValueIsValid ? 'Please fill out the field.' : ''"
          label="Password *"
        >
          <b-input
            v-model="form.value"
            :disabled="processing"
            type="password"
            password-reveal
            required
            placeholder="eg. tgge=LC7HUxAmEZMt"
            @input="valueChanged = true"
          />
        </b-field>

        <p v-if="!form.note.length && !showNoteField" class="has-text-centered">
          <a class="has-text-grey" @click="showNoteField = true">
            <u>Add a note relating to these credentials</u>
          </a>
        </p>

        <b-field v-if="form.note.length || showNoteField" label="Note">
          <b-input
            v-model="form.note"
            :disabled="processing"
            type="textarea"
            placeholder="eg. This is the username to access my admin area..."
            expanded
          />
        </b-field>
      </section>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="formIsDisabled"
        type="submit"
        class="button is-success"
      >
        Save
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "FixedVaultPasswordModal",
  props: {
    password: {
      type: Object,
      default: () => {
        return {
          label: "",
          username: "",
          value: "",
          note: ""
        };
      }
    }
  },
  data() {
    return {
      processing: false,
      labelChanged: false,
      valueChanged: false,
      form: this.$_.clone(this.password),
      showNoteField: false
    };
  },
  computed: {
    formLabelIsValid() {
      if (!this.labelChanged) {
        return true;
      }
      return this.form.label.length > 0;
    },
    formValueIsValid() {
      if (!this.valueChanged) {
        return true;
      }
      return this.form.value.length > 0;
    },
    formIsValid() {
      return this.formLabelIsValid && this.formValueIsValid;
    },
    formIsDisabled() {
      return !this.formIsValid || this.processing;
    },
    isPasswordExits() {
      return this.$_.has(this.form, "_id");
    },
    isPasswordDecoded() {
      return this.$_.has(this.form, "state") && this.form.state === "decoded";
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.label.$el.querySelector("input").focus();
    });
  },
  mounted() {
    this.decodeValue();
  },
  methods: {
    decodeValue() {
      if (!this.isPasswordExits || this.isPasswordDecoded) {
        return;
      }
      const value = this.form.value;
      this.processing = true;
      this.form.value = "Decoding...";
      this.$store
        .dispatch("vault/decodeValue", {
          value: value,
          passwordId: this.form._id
        })
        .then(result => {
          this.$set(this.form, "state", "decoded");
          this.$set(this.form, "value", result.value);
        })
        .catch(error => {
          this.$set(this.form, "value", value);
          this.$emit("close");
          this.$toast.open({
            message: `${error.message}`,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    submit() {
      this.labelChanged = true;
      this.valueChanged = true;
      if (this.formIsDisabled) {
        return;
      }
      let action = "vault/addPassword",
        params = {
          password: this.form
        };
      if (this.isPasswordExits) {
        action = "vault/updatePassword";
        params.passwordId = this.form._id;
      }

      this.processing = true;
      this.$store
        .dispatch(action, params)
        .then(result => {
          this.$emit("success");
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            type: "is-success",
            queue: false
          });
        })
        .catch(error => {
          this.processing = false;
          this.$toast.open({
            message: `${error.message}`,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        });
    }
  }
};
</script>
